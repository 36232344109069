import axios from 'axios';
import ICompanyForm from '@/types/ICompanyForm';
import ICompanySettingsForm from '@/types/ICompanySettingsForm';
import {IUserCreateForm} from '@/types/IUserCreateForm';
import {IUserEditForm} from '@/types/IUserEditForm';

export default class CompanyRepository {
  static fetchCompanyInfo(): Promise<any> {
    return axios.get('company/info');
  }

  static fetchCompanyUsers(page: number, size: number): Promise<any> {
    return axios.get(`company/user/${page}/${size}`);
  }

  static getUserRoles(): Promise<any> {
    return axios.get(`company/user/form`);
  }

  static updateCompanyAddress(data: ICompanyForm): Promise<any> {
    return axios.put(`company/address/${data.addressId}`, data);
  }

  static deleteCompanyUser(id: number): Promise<any> {
    return axios.delete(`company/user/${id}`);
  }

  static updateCompanySettings(data: ICompanySettingsForm): Promise<any> {
    return axios.post('company/params', data);
  }

  static createCompanyUser(data: IUserCreateForm): Promise<any> {
    return axios.post('company/user', data);
  }

  static updateCompanyUserRole(data: IUserEditForm): Promise<any> {
    return axios.put(`company/user/${data.id}/role`, {role: data.role});
  }

  static authorizeSPAPI() {
    return axios.get('company/authorize/spapi')
  }

  static exchangeSPAPI(api: any): Promise<any> {
      return axios.post('company/exchange/spapi', api);
  }
}
