import ICompanyInfo from '@/types/ICompanyInfo';
import CompanyRepository from '@/repositories/CompanyRepository';
import CompanyFactory from '@/factories/CompanyFactory';
import ICompanyForm from '@/types/ICompanyForm';
import ICompanySettingsForm from '@/types/ICompanySettingsForm';
import UserFactory from '@/factories/UserFactory';
import {IUserCreateForm} from '@/types/IUserCreateForm';
import {IUserEditForm} from '@/types/IUserEditForm';
import {IPagination} from '@/types/IPagination';

export default class CompanyService {
  static async getCompanyInfo(): Promise<ICompanyInfo> {
    const {data} = await CompanyRepository.fetchCompanyInfo();
    return CompanyFactory.toCompanyInfo(data);
  }

  static async getCompanyUsers(params: IPagination): Promise<any> {
    const {data} = await CompanyRepository.fetchCompanyUsers(params.page, params.size);
    return UserFactory.toUsersList(data);
  }

  static async updateCompanyAddress(data: ICompanyForm): Promise<any> {
    return CompanyRepository.updateCompanyAddress(data);
  }

  static async deleteCompanyUser(id: number): Promise<any> {
    return CompanyRepository.deleteCompanyUser(id);
  }

  static async updateCompanySettings(data: ICompanySettingsForm): Promise<any> {
    return CompanyRepository.updateCompanySettings(data);
  }

  static async getUserRoles(): Promise<any> {
    return CompanyRepository.getUserRoles();
  }

  static async createCompanyUser(data: IUserCreateForm): Promise<any> {
    return CompanyRepository.createCompanyUser(data);
  }

  static async updateCompanyUserRole(data: IUserEditForm): Promise<any> {
    return CompanyRepository.updateCompanyUserRole(data);
  }
  static async authorizeSPAPI(): Promise<any> {
    const {data} = await CompanyRepository.authorizeSPAPI();
    return data;
  }

  static async exchangeSPAPI(api: any) {
      await CompanyRepository.exchangeSPAPI(api);
  }
}
