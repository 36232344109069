import ICompanyInfo from '@/types/ICompanyInfo';

export default class CompanyFactory {
  static toCompanyInfo(data: any): ICompanyInfo {
    return {
      address: {
        addressId: data.address.addressId,
        city: data.address.city,
        postalCode: data.address.postalCode,
        address: data.address.address,
        countryId: data.address.countryId,
        companyName: data.name,
      },
      id: data.id,
      params: {
        accessKeyId: data.params.SP_API_ACCESS_KEY_ID,
        secretKeyId: data.params.SP_API_SECRET_KEY_ID,
        clientSecret: data.params.SP_API_CLIENT_SECRET,
        reportDate: data.params.REPORT_DATE,
        clientId: data.params.SP_API_CLIENT_ID,
        roleArn: data.params.SP_API_ROLE_ARN,
        appId: data.params.SP_API_APP_ID,
        refreshToken: data.params.SP_API_REFRESH_TOKEN
      },
    };
  }
}